import React from 'react';

const Skills = () => {
  return (
    <>
      <h3>Skills</h3>
      <h5>Web Programming</h5>
      <ul>
        <li>JavaScript</li>
        <li>TypeScript</li>
        <li>ReactJS</li>
        <li>NestJs</li>
        <li>Java</li>
        <li>NodeJs</li>
        <li>SQL</li>
      </ul>
      <h5>Design & Styling</h5>
      <ul>
        <li>CSS</li>
        <li>MUI</li>
        <li>Figma</li>
      </ul>
      <h5>Databases</h5>
      <ul>
        <li>PostgreSQL</li>
        <li>AWS Timestream</li>
        <li>DynamoDB</li>
      </ul>
      <h5>AWS Cloud Services</h5>
      <ul>
        <li>IoT Core</li>
        <li>S3</li>
        <li>Cognito</li>
        <li>Lambda</li>
      </ul>
    </>
  );
};

export default Skills;
